import React, { useState, useEffect } from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import Config from 'commons/config'
import * as Storage from 'commons/storage'
import { httpNotFound, isGlobalNotificationType, addDate } from 'commons/utility'
import FreeTermExtensionChallengeDialog from 'components/free-term-extension-challenge/FreeTermExtensionChallengeDialog'
import MovieEventAnnounceDialog from 'components/movie/AnnounceDialog'
import AdvicePhotoDialog from 'components/notifications/AdvicePhotoDialog'
import CooperateDialog from 'components/notifications/CooperateDialog'
import DocomoRugbyTicketPresentDialog from 'components/notifications/DocomoRugbyTicketPresentDialog'
import FBAnnounceDialog from 'components/notifications/FBAnnounceDialog'
import FeeRevisionDialog from 'components/notifications/FeeRevisionDialog'
import MonitorUserDialog from 'components/notifications/MonitorUserDialog'
import BaseComponent from 'components/parts/BaseComponent'
import ConfirmDialog from 'components/parts/ConfirmDialog.jsx'
import NewOrganizationsDialog from 'components/parts/NewOrganizationsDialog'
import PhotoFilter from 'components/parts/PhotoFilter'
import SpecialOfferDialog from 'components/special-offer/SpecialOfferDialog'
import ValentineEventChallengeDialog from 'components/valentine-event/ValentineEventChallengeDialog'
import WinterEventAnnounceDialog from 'components/winter-event/AnnounceDialog'
import WinterEventChallengeDialog from 'components/winter-event/WinterEventChallengeDialog'
import XmasCampaignAnnounceDialog from 'components/xmas-campaign/AnnounceDialog'
import IconImage from 'images/aill_goen_icon.png'

const useStyles = makeStyles(theme => ({
  item: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
    borderBottom: '1px solid #E0E0E0'
  },
  title: {
    margin: 0,
    width: 'calc(100% - 160px)',
  },
  titleText: {
    fontSize: 14,
    fontWeight: 300,
    color: theme.palette.secondary.main,
  },
  date: {
    margin: 0,
  },
  dateText: {
    fontSize: 14,
    fontWeight: 300,
    color: '#9D9D9E',
    width: 80,
    textAlign: 'right',
  },
  faceAvatar: {
    width: 48,
    height: 48,
    marginRight: theme.spacing(1),
  },
}))

export default function NotificationCell (props) {
  const { notification, readIds, ...others } = props
  const { friends, user } = props
  const classes = useStyles()
  const [alreadyRead, setAlreadyRead] = useState(false)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [dialogTitle, setDialogTitle] = useState('')
  const [dialogMessage, setDialogMessage] = useState('')
  const [openNewCompaniesDialog, setOpenNewCompaniesDialog] = useState(false)
  const [newCompanyNames, setNewCompanyNames] = useState([])
  // シアターイベント関連
  const [openMovieEventPreDialog, setOpenMovieEventPreDialog] = useState(false)
  const [openMovieEventDialog, setOpenMovieEventDialog] = useState(false)
  const [movieEventParams, setMovieEventParams] = useState(null)
  // 無料期間延長チャレンジ関連
  const [openFreeTermExtensionChallengeDialog, setOpenFreeTermExtensionChallengeDialog] = useState(false)
  const [friendApplyMission, setFriendApplyMission] = useState(null)
  const [chatOpenMission, setChatOpenMission] = useState(null)
  // ウィンターイベント2022関連
  const [openWinterEventAnnounceDialog, setOpenWinterEventAnnounceDialog] = useState(false)
  const [openWinterEventChallengeDialog, setOpenWinterEventChallengeDialog] = useState(false)
  const [winterEventMissions, setWinterEventMissions] = useState([])
  // バレンタインイベント2023関連
  const [openValentineEventChallengeDialog, setOpenValentineEventChallengeDialog] = useState(false)
  const [valentineEventMissions, setValentineEventMissions] = useState([])
  // クリスマスキャンペーン関連
  const [openXmasCampaignAnnounceDialog, setOpenXmasCampaignAnnounceDialog] = useState(false)
  const [campaignType, setCampaignType] = useState(null)
  const [campaignCode, setCampaignCode] = useState(null)
  // FB関連
  const [openFBAnnounceDialog, setOpenFBAnnounceDialog] = useState(false)
  const [notificationForFBAnnounceDialog, setNotificationForFBAnnounceDialog] = useState(null)
  const [openFBNewAnnounceDialog, setOpenFBNewAnnounceDialog] = useState(false)
  const [notificationForFBNewAnnounceDialog, setNotificationForFBNewAnnounceDialog] = useState(null)
  // ヒアリング系ポップアップ関連
  const [openMonitorUserDialog, setOpenMonitorUserDialog] = useState(false)
  const [monitorUserParams, setMonitorUserParams] = useState(null)
  const [openMonitorUserHearingDialog, setOpenMonitorUserHearingDialog] = useState(false)
  const [monitorUserHearingParams, setMonitorUserHearingParams] = useState(null)
  const [openPaidUserHearingDialog, setOpenPaidUserHearingDialog] = useState(false)
  const [paidUserHearingParams, setPaidUserHearingParams] = useState(null)
  const [openCoupleHearingDialog, setOpenCoupleHearingDialog] = useState(false)
  const [coupleHearingParams, setCoupleHearingParams] = useState(null)
  // スペシャルオファー関連
  const [openSpecialOfferDialog, setOpenSpecialOfferDialog] = useState(false)
  // docomo ラグビーチケット関連
  const [openDocomoRugbyTicketPresentDialog, setOpenDocomoRugbyTicketPresentDialog] = useState(false)
  // 写真へのアドバイス
  const [openAdvicePhotoDialog, setOpenAdvicePhotoDialog] = useState(false)
  // 値引き終了お知らせ
  const [openFeeRevisionDialog, setOpenFeeRevisionDialog] = useState(false)
  const [myPlan, setMyPlan] = useState(null)
  // 廃止予定
  const [openOrganizationsDialog, setOpenOrganizationsDialog] = useState(false)
  const [organizations, setOrganizations] = useState([])
  const notificationType = notification.notification_type
  const isGlobalNotif = isGlobalNotificationType(notificationType)
  const isContactNotif = (notificationType === 'contact')
  const isMission = (notificationType === 'mission_started' || notificationType === 'mission_achieved')
  const isAskPopup = notificationType.startsWith('Ask.')
  const isFeeRev = notificationType.startsWith('fee_revision_')
  const isMatchingUpdated = (notificationType === 'matching_updated')
  const isCampaign = notificationType.startsWith('campaign')
  const isMailBounced = (notificationType === 'mail_bounced')
  const isFbConnectExpired = (notificationType === 'fb_connect_expired')
  const isSpecialOffer = notificationType === 'special_offer'
  const isMonthlyFeeChange = (notificationType === 'monthly_fee_change')
  const isPhotoRejected = (/^[a-z]+_photo_rejected$/.test(notificationType))
  const isPhotoAdvice = (notificationType === 'photo_advice')
  const RugbyTicketPresent = (notificationType === 'rugby_ticket_present_20250208')
  const fromAill = (
    isGlobalNotif ||
    isContactNotif ||
    isMission ||
    isAskPopup ||
    isFeeRev ||
    isMatchingUpdated ||
    isCampaign ||
    isMailBounced ||
    isFbConnectExpired ||
    isSpecialOffer ||
    isMonthlyFeeChange ||
    isPhotoRejected ||
    isPhotoAdvice ||
    RugbyTicketPresent
  )

  useEffect(() => {
    if (!notification || !readIds) { return }
    updateIsRead(notification)
  }, [notification, readIds])

  const updateIsRead = (notification) => {
    if (notification?.already_read) {
      setAlreadyRead(true)
    } else if (fromAill) {
      setAlreadyRead(readIds.includes(notification.id))
    }
  }

  const withTransition = () => {
    switch (notificationType) {
      case 'friend_established': // 友達成立
      case 'friend_profile_updated': // 友達のプロフィール更新
      case 'friend_photo_updated': // 友達のプロフィール写真更新
      case 'friend_profile_increased': // 友達の公開プロフィール項目が増加
      case 'fav_rating_up': // 友達からの好感度が上昇
      case 'lover_entry_received': // お付き合い申請受信
        return searchFriend(notification.friend_user_id) > 0
      case 'friend_cancel': // 友達解消
      case 'friend_withdrawal': // 友達が退会した
      case 'friend_loved_other': // 友達が他人とお付き合い成立した
        return false
      default: // お付き合い申請お断りされた、Aillからのお知らせ
        return true
    }
  }

  const loadFriend = async () => {
    try {
      const res = await Api.getFriend(notification.friend_user_id)
      props.setFriend(res)
      return res
    } catch (error) {
      if (httpNotFound(error)) {
        BaseComponent.showErrorMessage(props, '友達解消されています。')
      } else {
        BaseComponent.handleApiError(props, error)
      }
    }

  }

  const transitionTo = async (screenName) => {
    props.setLoading(true)
    try {
      const friendData = await loadFriend(props)
      if (notificationType === 'friend_profile_increased' || notificationType === 'friend_profile_updated') {
        const profiles = notification.updated_profile_names.filter(profKey => friendData[profKey])
        if (profiles.length === 0) {
          props.setLoading(false)
          BaseComponent.showErrorMessage(props, `${friendData.nick_name} さんのこの項目は現在非公開となっています。`)
          return
        }
      }
      props.setLoading(false)
      props.setScreen(screenName)
    } catch (error) {
      props.setLoading(false)
      if (error.response && error.response.status === 404) {
        // noop
        return
      } else {
        BaseComponent.handleApiError(props, error)
      }
    }
  }

  const clickMessage = async () => {
    if (!alreadyRead) {
      await BaseComponent.updateAlreadyRead(props, notification)
      setAlreadyRead(true)
    }
    if (!withTransition()) { return }
    if (notificationType === 'friend_established') {
      // 友達成立
      transitionTo('Chat')
    } else if (notificationType === 'friend_pending') {
      // 友達成立(トーク開通保留)
      transitionTo('Friends')
    } else if (notificationType === 'friend_profile_updated') {
      // 友達のプロフィール更新
      Storage.setProfileSummaryIndex(0)
      transitionTo('IntroductionProfile')
    } else if (notificationType === 'friend_photo_updated') {
      // 友達のプロフィール写真更新
      Storage.setProfileSummaryIndex(0)
      transitionTo('IntroductionProfile')
    } else if (notificationType === 'friend_profile_increased') {
      // 友達の公開プロフィール項目が増加
      Storage.setProfileSummaryIndex(0)
      transitionTo('IntroductionProfile')
    } else if (notificationType === 'fav_rating_up') {
      // 友達からの好感度が上昇
      Storage.setProfileSummaryIndex(0)
      transitionTo('IntroductionProfile')
    } else if (notificationType === 'lover_entry_received') {
      // お付き合い申請受信
      Storage.setProfileSummaryIndex(0)
      transitionTo('IntroductionProfile')
    } else if (notificationType === 'lover_entry_rejected') {
      // お付き合い申請お断りされた
      if (notification.body) {
        const title = notification.friend_nick_name + 'さんからのメッセージ'
        setOpenConfirmDialog(true)
        setDialogTitle(title)
        setDialogMessage(notification.body)
      }
    } else if (notificationType === 'lover_entry_canceled') {
      setOpenConfirmDialog(true)
      setDialogTitle('お知らせ')
      setDialogMessage(notification.title)
    } else if (notificationType === 'campaign_achieved') {
      // キャンペーン達成
      window.open(Config.membersSiteUrl)
    } else if (notificationType === 'organization') {
      // 廃止予定
      // 参加企業更新のお知らせ
      const orgNames = await getOrganizationNames(notification.organization_ids)
      setOpenOrganizationsDialog(true)
      setOrganizations(orgNames)
    } else if (notificationType === 'new_companies') {
      // 参加企業更新のお知らせ
      setNewCompanyNames(notification.body.split(','),)
      setOpenNewCompaniesDialog(true)
    } else if (notificationType === 'movie_event_pre') {
      props.setLoading(true)
      try {
        // 映画予告イベントのお知らせ（開始前）
        const res = await Api.getMovieInfo(notification.movie_event_id)
        setOpenMovieEventPreDialog(true)
        setMovieEventParams(res)
      } catch (error) {
        BaseComponent.handleApiError(props, error)
      } finally {
        props.setLoading(false)
      }
    } else if (notificationType === 'movie_event') {
      props.setLoading(true)
      try {
        // 映画予告イベントのお知らせ（開始後）
        const res = await Api.getMovieInfo(notification.movie_event_id)
        setOpenMovieEventDialog(true)
        setMovieEventParams(res)
      } catch (error) {
        BaseComponent.handleApiError(props, error)
      } finally {
        props.setLoading(false)
      }
    } else if (notificationType === 'mission_started' && notification.mission_event_id === 'winter_event_2022') {
      props.setLoading(true)
      try {
        // ミッション取得
        const res = await Api.getMissions(notification.mission_event_id)
        setWinterEventMissions(res)
        const dailogDisplayed = await BaseComponent.isReadStateDialogDisplayed(props, 'winter_event_2022')
        dailogDisplayed ? setOpenWinterEventChallengeDialog(true) : setOpenWinterEventAnnounceDialog(true)
      } catch (error) {
        BaseComponent.handleApiError(props, error)
      } finally {
        props.setLoading(false)
      }
    } else if (notificationType === 'mission_started' && notification.mission_event_id === 'valentine_event_2023') {
      props.setLoading(true)
      try {
        const res = await Api.getMissions(notification.mission_event_id)
        setValentineEventMissions(res)
        setOpenValentineEventChallengeDialog(true)
      } catch (error) {
        BaseComponent.handleApiError(props, error)
      } finally {
        props.setLoading(false)
      }
    } else if (notificationType === 'campaign_started') {
      if (notification.campaign_type === 'xmas' || notification.campaign_type === 'xmas_for_free') {
        setCampaignType(notification.campaign_type)
        setCampaignCode(notification?.campaign_code)
      }
    } else if (notificationType === 'fb_connect_expired') {
      setOpenFBAnnounceDialog(true)
      setNotificationForFBAnnounceDialog(notification)
    } else if (notificationType === 'new_feature_fb_connect') {
      setOpenFBNewAnnounceDialog(true)
      setNotificationForFBNewAnnounceDialog(notification)
    } else if (notificationType === 'mission_achieved' && notification.mission_event_id === 'free_term_extension') {
      props.setLoading(true)
      try {
        // ミッション取得
        const res = await Api.getMissions(notification.mission_incentive_type)
        // フレンド申請ミッション抽出
        const friendApplyMission = res.find(mission => mission.mission_type === 'friend_apply')
        setFriendApplyMission(friendApplyMission)
        // トーク開通ミッション抽出
        const chatOpenMission = res.find(mission => mission.mission_type === 'chat_open')
        setChatOpenMission(chatOpenMission)
        setOpenFreeTermExtensionChallengeDialog(true)
      } catch (error) {
        BaseComponent.handleApiError(props, error)
      } finally {
        props.setLoading(false)
      }
    } else if (notificationType.startsWith('Ask.')) {
      // ヒアリング系
      const params = await getHearingParams(notificationType)
      if (notificationType === 'Ask.MonitorUser') {
        setOpenMonitorUserDialog(true)
        setMonitorUserParams(params)
      }
      if (notificationType === 'Ask.MonitorUserHearing') {
        setOpenMonitorUserHearingDialog(true)
        setMonitorUserHearingParams(params)
      }
      if (notificationType === 'Ask.PaidUserHearing') {
        setOpenPaidUserHearingDialog(true)
        setPaidUserHearingParams(params)
      }
      if (notificationType === 'Ask.CoupleHearing') {
        setOpenCoupleHearingDialog(true)
        setCoupleHearingParams(params)
      }
    } else if (notificationType === 'fee_revision_202302') {
      props.setLoading(true)
      try {
        const res = await Api.getMyPlan()
        setOpenFeeRevisionDialog(true)
        setMyPlan(res)
      } catch (error) {
        BaseComponent.handleApiError(props, error)
      } finally {
        props.setLoading(false)
      }
    } else if (notificationType === 'special_offer') {
      setOpenSpecialOfferDialog(true)
    } else if (isPhotoAdvice) {
      setOpenAdvicePhotoDialog(true)
    } else if (notificationType === 'matching_updated') {
      // 補填紹介のお知らせ
      setOpenConfirmDialog(true)
      setDialogTitle('「紹介」が更新されました！')
      setDialogMessage(notification.body)
    } else if (notificationType === 'rugby_ticket_present_20250208') {
      setOpenDocomoRugbyTicketPresentDialog(true)
    } else {
      // Aillからのお知らせ
      setOpenConfirmDialog(true)
      setDialogTitle(notification.title)
      setDialogMessage(notification.body)
    }
  }

  const getHearingParams = async (dialogType) => {
    // モニター募集
    if (dialogType === 'Ask.MonitorUser') {
      return { 'type': 'Ask.MonitorUser' }
    }

    props.setLoading(true)
    try {
      const res = await Api.getDialodMaster(dialogType)
      const detail = JSON.parse(res.detail)
      return {
        type: res.name,
        incentive_type: detail.incentive_type,
        incentive_name: detail.incentive_name,
        incentive_amount: detail.incentive_amount,
        answer_date: dateFormat(addDate(new Date(), detail.answer_days), 'yyyy-mm-dd'),
        form_url: detail.form_url,
      }
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const getPhotoIcon = () => {
    if (fromAill) { return IconImage }
    switch (notificationType) {
      case 'friend_cancel': // 友達解消
      case 'friend_withdrawal': // 友達が退会した
      case 'friend_loved_other': // 友達が他人とお付き合い成立した
        return null
      default:
        if (searchFriend(notification.friend_user_id) > 0) {
          return notification.photo_icon ? notification.photo_icon : null
        }
        return null
    }
  }

  const searchFriend = (friendId) => {
    if (!friendId) { return 0 }
    return friends.filter(friend => friend.id === friendId).length
  }

  const getDateString = (dateText) => {
    const today = new Date()
    const date = new Date(dateText)
    const diff = calcDateDiff(date, today)
    const y = date.getFullYear()
    const m = date.getMonth() + 1
    const d = date.getDate()
    return diff === 0 ? '今日' : diff === 1 ? '昨日' : `${y}.${m}.${d}`
  }

  const calcDateDiff = (d1, d2) => {
    const dates1 = (d1.getFullYear() * 10000) + (d1.getMonth() * 100) + d1.getDate()
    const dates2 = (d2.getFullYear() * 10000) + (d2.getMonth() * 100) + d2.getDate()
    return Math.abs(dates1 - dates2)
  }

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false)
    setDialogTitle('')
    setDialogMessage('')
  }

  // 廃止予定
  const getOrganizationNames = async (orgIds) => {
    const res = await Api.getOrganizations()
    const org_names = []
    orgIds.map(org_id => {
      const org = res.filter(org => org.id === org_id)
      if (org[0]) {
        org_names.push(org[0].canonical_name)
      }
    })
    return org_names
  }

  // 廃止予定
  const handleCloseOrganizations = () => {
    setOrganizations([])
    setOpenOrganizationsDialog(false)
  }

  // 廃止予定
  const handleMoveOrganizations = () => {
    handleCloseOrganizations()
    window.open(Config.memberCorporationsUrl, 'aill-member-corporations')
  }

  const onCloseNewCompaniesDialog = () => {
    setNewCompanyNames([])
    setOpenNewCompaniesDialog(false)
  }

  const goToOrganizations = () => {
    onCloseNewCompaniesDialog()
    window.open(Config.memberCorporationsUrl, 'aill-member-corporations')
  }

  const closeWinterEventAnnounceDialog = () => {
    BaseComponent.createReadStateDialogDisplayed(props, 'winter_event_2022')
    setOpenWinterEventAnnounceDialog(false)
  }

  return (
    <>
      <ListItem
        className={classes.item}
        onClick={clickMessage}
        style={{
          backgroundColor: alreadyRead ? '#F5F5F5' : fromAill ? '#FFFFFF' : '#EFF0FF'
        }}
      >
        <ListItemAvatar>
          <PhotoFilter brightness={notification.photo_filter_brightness}>
            <Avatar src={getPhotoIcon()} className={classes.faceAvatar} />
          </PhotoFilter>
        </ListItemAvatar>
        <ListItemText className={classes.title} >
          <Typography className={classes.titleText}>
            {notification.title}
          </Typography>
        </ListItemText>
        <ListItemText className={classes.date} >
          <Typography className={classes.dateText}>
            {getDateString(notification.notification_date || notification.created_at)}
          </Typography>
        </ListItemText>
      </ListItem>
      <ConfirmDialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        confirm="yes"
        title={dialogTitle}
        message={dialogMessage}
        messageLeft={isGlobalNotif || isContactNotif || isMonthlyFeeChange}
        imageUrl={notification?.image_url}
      />
      { /* 廃止予定 */}
      <NewOrganizationsDialog
        open={openOrganizationsDialog}
        onOk={handleMoveOrganizations}
        onClose={handleCloseOrganizations}
        organizations={organizations}
      />
      <NewOrganizationsDialog
        open={openNewCompaniesDialog}
        organizations={newCompanyNames}
        onOk={goToOrganizations}
        onClose={onCloseNewCompaniesDialog}
      />
      <MovieEventAnnounceDialog
        open={openMovieEventPreDialog}
        params={movieEventParams}
        onConfirm={() => props.setScreen('Theater')}
        onClose={() => setOpenMovieEventPreDialog(false)}
      />
      <MovieEventAnnounceDialog
        open={openMovieEventDialog}
        params={movieEventParams}
        onConfirm={() => props.setScreen('Theater')}
        onClose={() => setOpenMovieEventDialog(false)}
      />
      <WinterEventAnnounceDialog
        open={openWinterEventAnnounceDialog}
        onConfirm={() => {
          closeWinterEventAnnounceDialog()
          setOpenWinterEventChallengeDialog(true)
        }}
        onClose={closeWinterEventAnnounceDialog}
        missions={winterEventMissions}
      />
      <WinterEventChallengeDialog
        open={openWinterEventChallengeDialog}
        onClose={() => setOpenWinterEventChallengeDialog(false)}
        missions={winterEventMissions}
      />
      <ValentineEventChallengeDialog
        open={openValentineEventChallengeDialog}
        onClose={() => setOpenValentineEventChallengeDialog(false)}
        missions={valentineEventMissions}
      />
      <FreeTermExtensionChallengeDialog
        open={openFreeTermExtensionChallengeDialog}
        onClose={() => setOpenFreeTermExtensionChallengeDialog(false)}
        friendApplyMission={friendApplyMission}
        chatOpenMission={chatOpenMission}
        {...others}
      />
      <MonitorUserDialog
        open={openMonitorUserDialog}
        params={monitorUserParams}
        onClose={() => setOpenMonitorUserDialog(false)}
      />
      <MonitorUserDialog
        open={openMonitorUserHearingDialog}
        params={monitorUserHearingParams}
        onClose={() => setOpenMonitorUserHearingDialog(false)}
      />
      <CooperateDialog
        open={openPaidUserHearingDialog}
        params={paidUserHearingParams}
        onClose={() => setOpenPaidUserHearingDialog(false)}
        user={user}
      />
      <CooperateDialog
        open={openCoupleHearingDialog}
        params={coupleHearingParams}
        onClose={() => setOpenCoupleHearingDialog(false)}
        user={user}
      />
      <FeeRevisionDialog
        open={openFeeRevisionDialog}
        onClose={() => setOpenFeeRevisionDialog(false)}
        plan={myPlan}
      />
      <XmasCampaignAnnounceDialog
        open={openXmasCampaignAnnounceDialog}
        onClose={() => setOpenXmasCampaignAnnounceDialog(false)}
        type={campaignType}
        code={campaignCode}
      />
      <FBAnnounceDialog
        {...others}
        open={openFBAnnounceDialog}
        onConfirm={() => props.setScreen('Facebook')}
        onClose={() => setOpenFBAnnounceDialog(false)}
        notification={notificationForFBAnnounceDialog}
      />
      <FBAnnounceDialog
        {...others}
        open={openFBNewAnnounceDialog}
        onConfirm={() => props.setScreen('Facebook')}
        onClose={() => setOpenFBNewAnnounceDialog(false)}
        notification={notificationForFBNewAnnounceDialog}
      />
      <SpecialOfferDialog
        {...others}
        open={openSpecialOfferDialog}
        offerId={notification?.special_offer_id}
        onClick={() => props.setScreen('SpecialOffer')}
        onClose={() => setOpenSpecialOfferDialog(false)}
      />
      <AdvicePhotoDialog
        {...others}
        user={user}
        open={openAdvicePhotoDialog}
        onClose={async () => {
          setOpenAdvicePhotoDialog(false)
        }}
      />
      <DocomoRugbyTicketPresentDialog
        open={openDocomoRugbyTicketPresentDialog}
        onClose={() => setOpenDocomoRugbyTicketPresentDialog(false)}
      />
    </>
  )
}

NotificationCell.propTypes = {
  notification: PropTypes.object.isRequired,
  readIds: PropTypes.array.isRequired,
  friends: PropTypes.array,
  user: PropTypes.object,
  setLoading: PropTypes.func,
  setScreen: PropTypes.func,
  setFriend: PropTypes.func,
  setAdvicePhoto: PropTypes.func,
}

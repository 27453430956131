import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Komainu from 'images/Komainu/koma_wink.png'
import Left from 'images/speech_bubble_left.png'
import Right from 'images/speech_bubble_right.png'

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: 18,
    fontWeight: 600,
    color: '#ffffff',
    marginBottom: theme.spacing(1.5),
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
  },
}))

// TODO 狛犬の顔 + セリフ(左右吹き出し)の画像を共通化したい
export default function KomaSerif (props) {
  const { text } = props
  const classes = useStyles()

  return (
    <Grid container direction="column" alignItems="center">
      <Grid className={classes.text} container justifyContent="center" alignItems="center">
        <img src={Left} width={29.87} height={40.75} />
        {text}
        <img src={Right} width={29.87} height={40.75} />
      </Grid>
      <img src={Komainu} width={244.88} height={130.24} />
    </Grid>
  )
}

KomaSerif.propTypes = {
  text: PropTypes.string,
}

import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import FoodReportThemeProvider from 'components/foodreport/FoodReportThemeProvider'
import DocomoRugbyTicketPresentDialog from 'components/notifications/DocomoRugbyTicketPresentDialog'
import Kagura from 'images/img_character_a.png'
import Sakaki from 'images/img_character_un.png'
import KaguraFoot from 'images/tutorial/img_footprint_a.png'
import SakakiFoot from 'images/tutorial/img_footprint_un.png'

const useStyles = makeStyles(theme => ({
  root: {
    width: 338,
    fontWeight: 'bold',
    background: '#DBDCFE',
    borderRadius: 22,
    margin: 'auto',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(3.375),
    position: 'relative',
    textAlign: 'center',
    whiteSpace: 'pre-wrap'
  },
  sakaki: {
    width: 40,
    position: 'absolute',
    left: 10,
    bottom: 48,
    transform: 'rotate(20deg)',
  },
  kagura: {
    width: 32,
    position: 'absolute',
    right: 16,
    top: 152,
    transform: 'rotate(-15deg)',
  },
  message: {
    fontSize: 12,
    fontWeight: 600,
    color: '#1A1667',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  title: {
    fontSize: 14,
    fontWeight: 700,
    color: '#423BC7',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  eventName: {
    fontSize: 20,
    fontWeight: 700,
    color: '#555555',
    marginBottom: theme.spacing(1.5),
  },
  eventText: {
    fontSize: 12,
    fontWeight: 600,
    color: '#555555',
    marginBottom: theme.spacing(2),
  },
}))

export default function DocomoRugbyTicketPresentGuidance () {
  const classes = useStyles()
  const [openDocomoRugbyTicketPresentDialog, setOpenDocomoRugbyTicketPresentDialog] = useState(false)

  return (
    <FoodReportThemeProvider>
      <Grid
        className={classes.root}
        container
        direction="column"
        alignContent="center"
        alignItems="center"
      >
        <img className={classes.sakaki} src={Sakaki} alt="" />
        <img className={classes.kagura} src={Kagura} alt="" />
        <Grid container justifyContent="center" alignItems="center">
          <img src={KaguraFoot} width={22} alt="" />
          <Typography className={classes.message}>
            2人に見えるメッセージ
          </Typography>
          <img src={SakakiFoot} width={22} alt="" />
        </Grid>
        <Typography className={classes.title}>
          ２人へのスペシャルオファー
        </Typography>
        <Typography className={classes.eventName}>
          ラグビー観戦イベント in 東京
        </Typography>
        <Typography className={classes.eventText}>
          「2/8(土）浦安D-Rocks」を応援しよう！<br />
          NTT ドコモ×Aill コラボ企画
        </Typography>
        <Button variant="contained" onClick={() => setOpenDocomoRugbyTicketPresentDialog(true)}>
          イベント詳細を見る
        </Button>
      </Grid>
      <DocomoRugbyTicketPresentDialog
        open={openDocomoRugbyTicketPresentDialog}
        onClose={() => setOpenDocomoRugbyTicketPresentDialog(false)}
        pair
      />
    </FoodReportThemeProvider>
  )
}
DocomoRugbyTicketPresentGuidance.propTypes = {
}
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import SlideIconOpen from '@material-ui/icons/Brightness1'
import SlideIconClose from '@material-ui/icons/Brightness1Outlined'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import BaseComponent from 'components/parts/BaseComponent'
import AImage from 'images/img_character_a.png'
import UnImage from 'images/img_character_un.png'
import Img1 from 'images/tutorial/welcome_dialog_img_1.png'
import Img2 from 'images/tutorial/welcome_dialog_img_2.png'

const useStyles = makeStyles((theme) => ({
  slideIconsContainer: {
    width: '100%',
    color: '#ffffff',
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  slideIcon: {
    fontSize: 8,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  scrollbar: {
    '& ::-webkit-scrollbar': {
      display: 'none'
    }
  },
  slide: {
    position: 'relative',
    height: '100%',
  },
  text: {
    marginBottom: theme.spacing(1.5),
  },
  faceAvatarA: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    height: 24,
    objectFit: 'contain',
    transform: 'rotate(-15deg)',
  },
  faceAvatarUn: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    height: 24,
    objectFit: 'contain',
    transform: 'rotate(15deg)',
  },
  image1: {
    width: '100%',
    height: 360,
    marginTop: theme.spacing(2),
    objectFit: 'contain',
  },
  image2: {
    width: '100%',
    height: 360,
    marginBottom: theme.spacing(2),
    objectFit: 'contain',
  },
}))

export default function WelcomeDialog (props) {
  const { open, onClose } = props
  const classes = useStyles()
  const [slideIndex, setSlideIndex] = useState(0)
  const firstBoostEnabled = BaseComponent.isFirstMatchingBoostEnabled(props)

  useEffect(() => {
    if (!open) { return }
    setSlideIndex(0)
  }, [open])

  const onChangeSlideIndex = (index, latestIndex, meta) => {
    if (meta.reason === 'swipe') {
      setSlideIndex(index)
    }
  }

  const createPage1 = () => (
    <div style={Object.assign({})} className={classes.slide}>
      <Typography className={classes.text} variant="body1">
        毎日12時に更新
      </Typography>
      <Typography className={classes.text} variant="body1">
        毎日ログインして<br />出逢いを楽しもう！
      </Typography>
      <Typography className={classes.text} variant="body1">
        毎日1名、<br />
        相性の良い異性を紹介！<br />
        （初回のみ5名紹介）
      </Typography>
      <Typography className={classes.text} variant="body1">
        ご紹介から7日間、<br />
        お相手が表示されます
      </Typography>
      <Typography className={classes.text} variant="body1">
        ＊スタンダードプランご利用時のみ
        {firstBoostEnabled && <><br />＊初回ブースト期間終了後</>}
      </Typography>
      <Grid container justifyContent="center">
        <img className={classes.image1} src={Img1} />
      </Grid>
    </div>
  )

  const createPage2 = () => (
    <div style={Object.assign({})} className={classes.slide}>
      <Typography className={classes.text} variant="body1">
        「いいね！」
      </Typography>
      <Typography className={classes.text} variant="body1">
        気になる異性に<br />
        「いいね！」を送るボタンをタップ！
      </Typography>
      <Typography className={classes.text} variant="body1">
        相手が「いいね！」を返信した場合、<br />
        2人のトークルームが表示されます。
      </Typography>
      <Grid container justifyContent="center">
        <img className={classes.image2} src={Img2} />
      </Grid>
    </div>
  )

  return (
    <DialogThemeProvider color="secondary">
      <Dialog open={open}>
        <DialogTitle disableTypography>
          <Grid container justifyContent="center" alignItems="center">
            <img src={AImage} className={classes.faceAvatarA} alt="" />
            本日の出逢い
            <img src={UnImage} className={classes.faceAvatarUn} alt="" />
          </Grid>
        </DialogTitle>
        <DialogContent>
          <SwipeableViews index={slideIndex} onChangeIndex={onChangeSlideIndex}>
            {createPage1()}
            {createPage2()}
          </SwipeableViews>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="center">
            <div className={classes.slideIconsContainer}>
              {slideIndex === 0 ? <SlideIconOpen className={classes.slideIcon} /> : <SlideIconClose className={classes.slideIcon} />}
              {slideIndex === 1 ? <SlideIconOpen className={classes.slideIcon} /> : <SlideIconClose className={classes.slideIcon} />}
            </div>
            <Button
              variant="contained"
              onClick={() => slideIndex === 1 ? onClose() : setSlideIndex(slideIndex + 1)}
            >
              {slideIndex === 1 ? '閉じる' : '次へ'}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

WelcomeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

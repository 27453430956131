import React from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import * as storage from 'commons/storage'
import DisableCookieScreen from 'components/DisableCookieScreen'
import EntrySteps from 'components/entry/EntrySteps'
import IdDescription from 'components/entry/IdDescription'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'
import ConfirmDialog from 'components/parts/ConfirmDialog.jsx'

const styles = theme => ({
  root: {
    paddingBottom: theme.spacing(2),
    maxWidth: 700,
    margin: 'auto',
  },
  body: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  notice: {
    fontSize: 16,
    color: '#FF0000',
    opacity: 0.54,
    marginBottom: theme.spacing(2),
  },
  annotation: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: 14,
  },
  entry: {
    ...theme.styles.entryButton,
    fontSize: 16,
    fontWeight: 'bold',
    width: theme.spacing(28.75),
    height: theme.spacing(5.5),
    marginTop: theme.spacing(3),
  },
  back: {
    marginTop: theme.spacing(3),
  },
  next: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(5.33),
  },
  text: {
    marginTop: theme.spacing(4),
  },
  textRed: {
    fontSize: 16,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#ff69b4',
  },
  textCenter: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  example: {
    width: theme.spacing(35),
    height: 'auto',
  },
})

export class Identify extends BaseComponent {
  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleReadImportedFile = this.handleReadImportedFile.bind(this)
    this.goHome = this.goHome.bind(this)
    this.dialogClose = this.dialogClose.bind(this)
    this.state = {
      isPending: false,
      uploaded: false,
    }
  }

  async componentWillMount () {
    const { identification_status, pending_reasons } = this.props.user
    if (identification_status === 'pending' && !pending_reasons.includes('profile') && !pending_reasons.includes('photo') && pending_reasons.includes('identification') && !storage.getShowPendingDialog()) {
      this.setState({ isPending: true })
    }
  }

  componentDidMount () {
    // this.uploadCheck()
    this.addVisitPageLog()
  }

  componentWillUnmount () {
    this.addLeavePageLog()
  }

  handleChange (event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleReadImportedFile (event) {
    this.props.setIdentifyImage(event.target.files[0])
    this.props.setScreen('IdentifyConfirm')
  }

  goHome () {
    this.props.setScreen('Home')
  }

  dialogClose () {
    storage.setShowPendingDialog(true)
    this.setState({ isPending: false })
  }

  uploadCheck () {
    const { identification_status, pending_reasons } = this.props.user
    if (!(identification_status === 'pending' && pending_reasons.includes('identification'))) {
      this.setState({ uploaded: true })
    }
  }

  render () {
    if (!storage.default.enabled) {
      return <DisableCookieScreen />
    }

    const { classes, ...others } = this.props
    const { idText } = this.props
    return (
      <div className={classes.root}>
        <ConfirmDialog
          open={this.state.isPending}
          onClose={this.dialogClose}
          onOk={this.dialogClose}
          onCancel={this.dialogClose}
          confirm="yes" title="入会審査保留"
          message={`この度はAillの入会登録、ありがとうございます。\nご登録いただいた${idText?.label}に不備がございました。再度添付をお願いいたします。`}
        />
        <AppHeader title="確認書類1（本人確認）" hideBack={true} {...others} />
        <EntrySteps step={3} titles={['基本情報', '恋愛情報', '写真登録', '確認書類', '入会審査']} />
        <div className={classes.body}>
          <Grid container direction='column' alignItems="center" justifyContent="center" className={classes.root}>
            <Grid item>
              <IdDescription {...others} />
              <Typography className={classes.notice}>
                {this.textConvertMultiline(idText?.description_1)}<br />
                {this.textConvertMultiline(idText?.description_2)}
              </Typography>
              <Typography className={classes.annotation}>
                {this.textConvertMultiline(idText?.description_3)}
              </Typography>
            </Grid>
          </Grid>
          <form className={classes.container} noValidate autoComplete="off">
            <input
              id="file"
              type="file"
              accept="image/*"
              onChange={this.handleReadImportedFile}
              style={{
                width: 0,
                height: 0,
                opacity: 0,
                overflow: 'hidden',
                position: 'absolute',
                zIndex: 1,
              }}
              data-testid="identify_img"
            />
          </form>
          <Grid container direction='column' alignItems="center" justifyContent="center" className={classes.root}>
            {
              this.state.uploaded &&
              <Grid item>
                <Typography className={classes.textRed}>
                  提出済みです
                </Typography>
              </Grid>
            }
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                className={classes.entry}
                htmlFor="file"
                component="label"
              >
                { this.state.uploaded ? '変更する' : 'フォトライブラリから選ぶ' }
              </Button>
              {
                this.state.uploaded &&
                <Grid container direction='column' alignItems="center" justifyContent="center">
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.back}
                      onClick={() => this.props.setScreen('Photo')}
                    >
                      戻る
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.next}
                      onClick={() => this.props.setScreen('PaymentRegistration')}
                    >
                      次へ
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
            {idText?.note && (
              <Grid item>
                <Typography className={classes.text}>
                  {idText.note}
                </Typography>
              </Grid>
            )}
            {idText?.sample_image_url && (
              <Grid item>
                <Typography className={classes.textCenter}>
                  ＜例＞
                </Typography>
                <img src={idText.sample_image_url} className={classes.example} alt="例" />
              </Grid>
            )}
            {
              !this.state.uploaded &&
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.back}
                  onClick={() => this.props.setScreen('Photo')}
                >
                  戻る
                </Button>
              </Grid>
            }
          </Grid>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(Identify)

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import * as storage from 'commons/storage'
import DisableCookieScreen from 'components/DisableCookieScreen'
import EntrySteps from 'components/entry/EntrySteps'
import IdDescription from 'components/entry/IdDescription'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'
import ConfirmDialog from 'components/parts/ConfirmDialog.jsx'

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(2),
    maxWidth: 700,
    margin: 'auto',
  },
  body: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  notice: {
    fontSize: 16,
    color: '#FF0000',
    opacity: 0.54,
    marginBottom: theme.spacing(2),
  },
  annotation: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: 14,
  },
  entry: {
    ...theme.styles.entryButton,
    fontSize: 16,
    fontWeight: 'bold',
    width: theme.spacing(28.75),
    height: theme.spacing(5.5),
    marginTop: theme.spacing(3),
  },
  back: {
    marginTop: theme.spacing(3),
  },
  next: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(5.33),
  },
  text: {
    marginTop: theme.spacing(4),
  },
  textRed: {
    fontSize: 16,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#ff69b4',
  },
  textCenter: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  example: {
    width: theme.spacing(35),
    height: 'auto',
  },
}))

export default function Affiliation (props) {
  const { user, affiliationText } = props
  const classes = useStyles()
  const [isPending, setIsPending] = useState(false)

  useEffect(() => {
    BaseComponent.addVisitPageLog()
    return () => {
      BaseComponent.addLeavePageLog()
    }
  }, [])

  useEffect(() => {
    if (!user) { return }
    const idStatus = user.identification_status
    const reason = user.pending_reasons
    setIsPending(idStatus === 'pending' && reason.includes('affiliation') && !storage.getShowPendingDialog())
  }, [user])

  const handleReadImportedFile = (event) => {
    props.setAffiliationImage(event.target.files[0])
    props.setScreen('AffiliationConfirm')
  }

  if (!storage.default.enabled) {
    return <DisableCookieScreen />
  }

  return (
    <div className={classes.root}>
      <ConfirmDialog
        open={isPending}
        onClose={() => {
          storage.setShowPendingDialog(true)
          setIsPending(false)
        }}
        confirm="yes"
        title="入会審査保留"
        message={`この度はAillの入会登録、ありがとうございます。ご登録いただいた${affiliationText?.label}に不備がございました。再度添付をお願いいたします。`}
      />
      <AppHeader title="確認書類2（所属確認）" hideBack {...props} />
      <EntrySteps step={3} titles={['基本情報', '恋愛情報', '写真登録', '確認書類', '入会審査']} />
      <div className={classes.body}>
        <Grid className={classes.root} container direction='column' alignItems="center" justifyContent="center">
          <Grid item>
            <IdDescription {...props} />
            <Typography className={classes.notice}>
              {BaseComponent.textConvertMultiline(affiliationText?.description_1)}<br />
              {BaseComponent.textConvertMultiline(affiliationText?.description_2)}
            </Typography>
            <Typography className={classes.annotation}>
              {BaseComponent.textConvertMultiline(affiliationText?.description_3)}
            </Typography>
          </Grid>
        </Grid>
        <form className={classes.container} noValidate autoComplete="off">
          <input
            id="file"
            type="file"
            accept="image/*"
            onChange={handleReadImportedFile}
            style={{
              width: 0,
              height: 0,
              opacity: 0,
              overflow: 'hidden',
              position: 'absolute',
              zIndex: 1,
            }}
            data-testid="affiliation_img"
          />
        </form>
        <Grid className={classes.root} container direction='column' alignItems="center" justifyContent="center">
          <Button
            variant="contained"
            color="secondary"
            className={classes.entry}
            htmlFor="file"
            component="label"
          >
            フォトライブラリから選ぶ
          </Button>
          {affiliationText?.note && (
            <Grid item>
              <Typography className={classes.text}>
                {affiliationText.note}
              </Typography>
            </Grid>
          )}
          {affiliationText?.sample_image_url && (
            <Grid item>
              <Typography className={classes.textCenter}>
                ＜例＞
              </Typography>
              <img src={affiliationText.sample_image_url} className={classes.example} alt="例" />
            </Grid>
          )}
          <Button
            variant="contained"
            color="secondary"
            className={classes.back}
            onClick={() => props.setScreen('Identify')}
          >
            戻る
          </Button>
        </Grid>
      </div>
    </div>
  )
}

Affiliation.propTypes = {
  user: PropTypes.object,
  affiliationText: PropTypes.object,
  setScreen: PropTypes.func,
  setAffiliationImage: PropTypes.func,
}

import { isNullOrUndefined } from 'util'
import dateFormat from 'dateformat'
import heartEmpty from '../images/heart_empty.png'
import heartFull from '../images/heart_full.png'
import heartHalf from '../images/heart_half.png'
import Config from './config'
import Const from './constant'

export function calcAge (day) {
  const today = new Date()
  const birthday = new Date(day)
  let age = today.getFullYear() - birthday.getFullYear()
  // 今年の誕生日
  const currentYearBirthday = new Date(
    today.getFullYear(),
    birthday.getMonth(),
    birthday.getDate(),
  )
  if (today < currentYearBirthday) {
    age = age - 1
  }
  return age
}

export function getDateTimeString (date) {
  let y = date.getFullYear()
  let m = ('00' + (date.getMonth() + 1)).slice(-2)
  let d = ('00' + date.getDate()).slice(-2)
  let h = ('00' + date.getHours()).slice(-2)
  let mn = ('00' + date.getMinutes()).slice(-2)
  let result = y + '-' + m + '-' + d + ' ' + h + ':' + mn

  return result
}

export function getDateString (date) {
  let today = new Date()
  let diff = calcDateDiff(date, today)
  if (diff === 0) {
    return '今日'
  } else if (diff === 1) {
    return '昨日'
  } else {
    let y = date.getFullYear()
    let m = date.getMonth() + 1
    let d = date.getDate()
    let yobi = ['日', '月', '火', '水', '木', '金', '土'][date.getDay()]
    if (y === today.getFullYear()) {
      return `${m}月${d}日(${yobi})`
    } else {
      return `${y}年${m}月${d}日(${yobi})`
    }
  }
}

// 日数の差
export function calcDateDiff (d1, d2) {
  const date1 = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate())
  const date2 = new Date(d2.getFullYear(), d2.getMonth(), d2.getDate())
  const msecDiff = Math.abs(date1.getTime() - date2.getTime())
  return Math.floor(msecDiff / (24 * 3600 * 1000))
}

export function addDate (date, delta) {
  if (typeof date === 'string') {
    date = new Date(date)
  }
  return new Date(
    date.getFullYear(), date.getMonth(), date.getDate() + delta,
    date.getHours(), date.getMinutes(), date.getSeconds()
  )
}

export function addMonth (date, delta) {
  if (typeof date === 'string') {
    date = new Date(date)
  }
  return new Date(
    date.getFullYear(), date.getMonth() + delta, date.getDate(),
    date.getHours(), date.getMinutes(), date.getSeconds()
  )
}

export function addHours (date, delta) {
  if (typeof date === 'string') {
    date = new Date(date)
  }
  return new Date(
    date.getFullYear(), date.getMonth(), date.getDate(),
    date.getHours() + delta, date.getMinutes(), date.getSeconds()
  )
}

export function addSeconds (date, delta) {
  if (typeof date === 'string') {
    date = new Date(date)
  }
  return new Date(
    date.getFullYear(), date.getMonth(), date.getDate(),
    date.getHours(), date.getMinutes(), date.getSeconds() + delta
  )
}

export function formatJapaneseDate (val) {
  try {
    let date = new Date(val)
    let y = date.getFullYear()
    let m = date.getMonth() + 1
    let d = date.getDate()
    return `${y}年${m}月${d}日`
  } catch (e) {
    console.log(e)
    return val
  }
}

export function formatJapaneseDateTime (val) {
  try {
    let date = new Date(val)
    let y = date.getFullYear()
    let m = date.getMonth() + 1
    let d = date.getDate()
    let h = date.getHours()
    let mn = ('00' + date.getMinutes()).slice(-2)
    return `${y}年${m}月${d}日 ${h}:${mn}`
  } catch (e) {
    console.log(e)
    return val
  }
}

export function formatJapaneseDateMD (val) {
  try {
    let date = new Date(val)
    let m = date.getMonth() + 1
    let d = date.getDate()
    return `${m}月${d}日`
  } catch (e) {
    console.log(e)
    return val
  }
}

export function formatRoughDate (val) {
  try {
    const date = new Date(val)
    const y = date.getFullYear()
    const m = date.getMonth() + 1
    let d = ''
    if (d <= 10) {
      d = '上旬'
    } else if (d <= 20) {
      d = '中旬'
    } else {
      d = '下旬'
    }
    return `${y}年${m}月${d}`
  } catch (e) {
    console.log(e)
    return val
  }
}

export function formatPrevJapaneseDate (date) {
  if (!date) { return }
  if (typeof date === 'string') {
    date = new Date(date)
  }
  return dateFormat(addDate(date, -1), 'yyyy年m月d日')
}

export function formatSlashDate (val) {
  try {
    let date = new Date(val)
    let y = date.getFullYear()
    let m = date.getMonth() + 1
    let d = date.getDate()
    return `${y}/${m}/${d}`
  } catch (e) {
    console.log(e)
    return val
  }
}

export function calculateAddDate (date, count) {
  // 日付加算
  date.setDate(date.getDate() + count)
  return date
}

// 経過月数計算
export function calculateMonthDiff (date1, date2) {
  let months = (date2.getFullYear() - date1.getFullYear()) * 12
  months -= date1.getMonth() + 1
  months += date2.getMonth()
  if (date1.getDate() <= date2.getDate()) { months += 1 }
  return months
}

// ２つの日付の時間差分計算
export function calculateHourDiff (date1, date2) {
  // getTimeメソッドで経過ミリ秒を取得し、２つの日付の差を求める
  let msDiff = date2.getTime() - date1.getTime()

  // 求めた差分（ミリ秒）を時間へ変換
  let hourDiff = Math.floor(msDiff / (1000 * 60 * 60))

  return hourDiff
}

// ２つの日付の時間差分（分刻み）計算
export function calculateMinuteDiff (date1, date2) {
  // getTimeメソッドで経過ミリ秒を取得し、２つの日付の差を求める
  let msDiff = date2.getTime() - date1.getTime()

  // 求めた差分（ミリ秒）を分へ変換
  let hourDiff = Math.floor(msDiff / (1000 * 60))

  return hourDiff
}

export function calculateRemainingDays (entryAt, type) {
  if (!entryAt) { return }
  let today = new Date()
  let entryDay = new Date(entryAt)

  if (type === 'friend') {
    entryDay.setDate(entryDay.getDate() + Const.friend_expiration_days)
  } else if (type === 'lover') {
    entryDay.setDate(entryDay.getDate() + Const.lover_expiration_days)
  }

  // getTimeメソッドで経過ミリ秒を取得し、２つの日付の差を求める
  let msDiff = entryDay.getTime() - today.getTime()

  // 求めた差分（ミリ秒）を日付へ変換します（経過ミリ秒÷(1000ミリ秒×60秒×60分×24時間)。端数切り捨て）
  let daysDiff = Math.floor(msDiff / (1000 * 60 * 60 * 24))

  // 求めた差分（ミリ秒）を時間へ変換
  let hourDiff = Math.floor(msDiff / (1000 * 60 * 60))

  // 差分へ1日分加算して返却します
  ++daysDiff

  if (daysDiff < 0) {
    return null
  } else if (daysDiff <= 1) {
    return '●　残り' + hourDiff + '時間！'
  } else {
    return '●　残り' + daysDiff + '日！'
  }
}

export function calculateAge (birthday) {
  let birth = birthday.split('-') // birth[2]: year, birth[0]: month, birth[1]: day
  let _birth = parseInt('' + birth[0] + birth[1] + birth[2], 10)// 文字列型に明示変換後にparseInt
  let today = new Date()
  let _today = parseInt('' + today.getFullYear() + affixZero(today.getMonth() + 1) + affixZero(today.getDate()), 10)// 文字列型に明示変換後にparseInt
  return parseInt((_today - _birth) / 10000, 10)
}

function affixZero (int) {
  if (int < 10) int = '0' + int
  return '' + int
}

export function textOverflowEllipsis (text, len, truncation) {
  if (truncation === undefined) { truncation = '...' }
  let text_array = text.split('')
  let count = 0
  let str = ''
  for (let i = 0; i < text_array.length; i++) {
    let n = escape(text_array[i])
    if (n.length < 4) count++
    else count += 2
    if (count > len) {
      return str + truncation
    }
    str += text.charAt(i)
  }
  return text
}

export function isEmpty (characters) {
  if (characters === undefined || characters === null) {
    return true
  }
  if (characters.length === 0) {
    return true
  }
  return false
}

export function isPostcode (postcode) {
  if (isEmpty(postcode)) { return false }
  if (postcode.match(/^[0-9０-９]{3}[-−ー]?[0-9０-９]{4}$/)) { return true }
  return false
}

// メール、パスワード、ニックネームで使用できない文字
// スペース、特殊文字（/、￥、＆、"、'、<、>）
export function isUsableCharacters (characters) {
  if (characters.match(/[\s/¥&'"<>]/)) {
    return false
  }
  return true
}

// 半角全角英字、ひらがな、カタカナ、漢字を1文字以上含むこと
// 参考: https://so-zou.jp/software/tech/programming/tech/regular-expression/meta-character/variable-width-encoding.htm
export function validateStringCode (str) {
  const regex = /[a-zA-Zａ-ｚＡ-Ｚｱ-ﾝぁ-んァ-ヶ]+/
  const kanjiRegex = /[\u3400-\u9FFF\uF900-\uFAFF]|[\uD840-\uD87F][\uDC00-\uDFFF]/
  return regex.test(str) || kanjiRegex.test(str)
}

// 平仮名チェック
export function isHiragana (str) {
  str = isNullOrUndefined(str) ? '' : str
  if (str.match(/^[ぁ-んー ]*$/)) {
    return true
  } else {
    return false
  }
}

// 日付チェック
export function isDate (y, m, d) {
  let dt = new Date(y, m - 1, d)
  return (dt.getFullYear() === y && dt.getMonth() === (m - 1) && dt.getDate() === d)
}

export function encodeSanitaize (str) {
  if (isEmpty(str)) {
    return null
  }
  return str.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#39;')
}

export function decodeSanitaize (str) {
  if (isEmpty(str)) {
    return null
  }
  return str.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&quot;/g, '"').replace(/&#39;/g, '\'').replace(/&amp;/g, '&')
}

export function getFileExt (file) {
  let segments = file.name.split('.')
  return segments[segments.length - 1]
}

export function getFavHearts (favPoint) {
  return [1, 2, 3, 4, 5].map(n => {
    // 好感度表示は最低2(ハート1個)とする
    if (n <= 1) { return heartFull }

    if (((n * 20) - 10) < favPoint) { return heartFull }
    if (((n * 20) - 20) < favPoint) { return heartHalf }
    return heartEmpty
  })
}

// プロフィール公開固定項目名
export const profilePublicFieldNames = [
  'nick_name',
  'birthday',
  'address1',
  'holiday',
  'how_to_spend_the_holidays',
  'marriage_intention',
  'sibling',
  'blood_type',
  'liquor',
  'to_message',
  'job_worthwhile',
  'birthplace',
  'u_turn_intention',
  'working_industry',
  'working_occupation',
  'date_timing',
]

// プロフィール非公開固定項目名
export const profilePrivateFieldNames = [
  'first_name',
  'other_education',
  'major_subject',
  'not_disclose_company_name',
  'main_working_company_name',
  // 'main_working_division_name',
  // 'main_working_department_name',
  // 'main_working_same_department_matching',
  // 'loan_working_company_name',
  // 'loan_working_division_name',
  // 'loan_working_department_name',
  // 'loan_working_same_department_matching',
  'same_organization_matching',
]

// プロフィール公開項目名チェック
export function isProfilePublicFieldName (key, value = null) {
  if (profilePublicFieldNames.includes(key)) {
    return true
  }
  // GOEN-657 家事育児を公開固定に変更
  // 現状、非公開の場合はカスタム項目とする。公開にした後は公開固定とする。
  if (key === 'ideal_housework_and_childcare' || key === 'acceptable_housework_and_childcare') {
    if (value === true) { return true }
  }
  return false
}

// プロフィール非公開項目名チェック
export function isProfilePrivateFieldName (key) {
  if (profilePrivateFieldNames.includes(key)) {
    return true
  }
  return false
}

// プロフィール除外項目取得
export function getProfileIgnoredKeys () {
  return [
    'final_education',
    'other_education',
    'major_subject',
    'not_disclose_company_name',
    'job_worthwhile',
    'main_working_division_name',
    'main_working_department_name',
    'main_working_same_department_matching',
    'loan_working_company_name',
    'loan_working_division_name',
    'loan_working_department_name',
    'loan_working_same_department_matching',
  ]
}

// プロフィール除外項目チェック
export function isProfileIgnoredKeys (key) {
  return getProfileIgnoredKeys().includes(key)
}

// 郵便番号に'−'（ハイフン）付与
export function addHyphenToPostcode (postcode) {
  return postcode.slice(0, 3) + '-' + postcode.slice(3)
}

// 郵便番号から'−'（ハイフン）を削除
export function deleteHyphenToPostcode (postcode) {
  if (!postcode) return null
  return postcode.replace('-','')
}

// URLからパラメータを取得
export function getUrlParam (name, url) {
  if (!url) url = window.location.href
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  let results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

// S3事前署名URLの期限が過ぎているか
export function expiredUrl (s3PresignedUrl) {
  if (!s3PresignedUrl) { return false }

  let url = new URL(s3PresignedUrl)
  let dateVal = url.searchParams.get('X-Amz-Date')
  let expiresVal = url.searchParams.get('X-Amz-Expires')

  if (!dateVal) { return false }
  if (!expiresVal) { return false }

  let date = parseDate(dateVal)
  let expSecs = parseInt(expiresVal, 10)
  let expTime = date.getTime() + (expSecs * 1000)
  return expTime <= Date.now()
}

function parseDate (str) {
  if (str.match(/\d{8}T\d{6}Z/)[0] === str) {
    // YYYYMMDD'T'HHMMSS'Z'
    let ymd = [
      str.substring(0, 4),
      str.substring(4, 6),
      str.substring(6, 8)
    ]
    let hms = [
      str.substring(9, 11),
      str.substring(11, 13),
      str.substring(13, 15)
    ]
    return new Date(`${ymd.join('-')}T${hms.join(':')}Z`)
  } else {
    return new Date(str)
  }
}

// 友達紹介キャンペーンコード付き会員登録画面URL
export function getSignupUrlWithCode (code) {
  let sub_domain = (process.env.NODE_ENV === 'production' && !Config.stagingEnv) ? 'entry' : 'entry-dev'
  return `https://${sub_domain}.aill.ai/?campaign_code=${code}`
}

// 秘密の質問チェック
export function isSecretQuestion (msg) {
  return !isNullOrUndefined(msg.secret_question_type)
}

// 食レポイベントチェック
export function isFoodReport (msg) {
  switch (msg.campaign_type) {
    case 'jr_west_poc':
      return true
    default:
      return false
  }
}

// デート後フォローガイダンスチェック
export function isDatingAfterFollowGuidance (msg) {
  return msg.message_type === 'dating_after_follow_guidance'
}

// デートおすすめ質問チェック
export function isDatingRecommendedQuestions (msg) {
  return msg.message_type === 'dating_recommended_questions'
}

// トークアシストガイダンスチェック
export function isTalkAssistGuidance (msg) {
  return msg.message_type === 'talk_assist_guidance'
}

// docomo ラグビーチケット
export function isRugbyTicketPresent20250208 (msg) {
  return msg.message_type === 'rugby_ticket_present_20250208'
}

export function httpNotModified (error) {
  return error.response && error.response.status === 304
}

export function httpBadRequest (error) {
  return error.response && error.response.status === 400
}

export function httpForbidden (error) {
  return error.response && error.response.status === 403
}

export function httpNotFound (error) {
  return error.response && error.response.status === 404
}

export function httpConflict (error) {
  return error.response && error.response.status === 409
}

export async function fetchResponseErrorMessage (error) {
  const contentType = error.response?.headers.get('Content-Type')
  if (contentType && contentType.indexOf('application/json') === 0) {
    const body = await error.response.json()
    return body.message
  }
  return ''
}

// 通信エラー判定
export function networkConnectionError (error) {
  if (error.response) { return false }
  if (error instanceof TypeError) {
    if (error.message === 'Failed to fetch') { return true }

    // iOS（Safari）で発生するエラーメッセージ
    const iosErrorMessages = [
      'Preflight response is not successful',
      'cannot parse response',
      'An SSL error has occurred and a secure connection to the server cannot be made.',
      'The Internet connection appears to be offline.',
      'The operation couldn’t be completed. Software caused connection abort',
    ]
    if (iosErrorMessages.includes(error.message)) { return true }
  }
  return false
}

// 全体お知らせか
export function isGlobalNotificationType (type) {
  const globalType = [
    'global',
    'new_companies',
    'movie_event_pre',
    'movie_event',
    'new_feature_fb_connect',
    // 廃止予定
    'organization',
  ]
  return globalType.includes(type)
}

// 恋愛情報登録済みか
export function isCompletedLoveSheet (user) {
  if (!user) return false
  if (isNullOrUndefined(user.how_to_spend_the_holidays)) return false
  return true
}

export function getMediumItems () {
  const mediumItems = [
    {label: 'web', value: 'web'},
    {label: 'メール', value: 'mail'},
    {label: '掲示板', value: 'bulletin_board'},
    {label: 'パンフレット', value: 'pamphlet'},
    {label: 'ポスター', value: 'poster'},
    {label: 'ポスター（男性向け）', value: 'poster_male'},
    {label: 'ポスター（女性向け）', value: 'poster_female'},
    {label: 'サービスカード', value: 'card'},
    {label: 'ソーシャル', value: 'social'},
    {label: 'その他', value: 'others'},
  ]
  return mediumItems
}

export function getMediumLabel (value) {
  let label = null
  getMediumItems().some((item) => {
    if (item.value === value) {
      label = item.label
    }
    return null
  })
  return label
}

export function getAreaItems () {
  const areaItems = [
    {label: '全国', value: 'all'},
    {label: '九州', value: 'kyusyu'},
    {label: '関西', value: 'kansai'},
  ]
  return areaItems
}

export function getAreaLabel (value) {
  let label = null
  getAreaItems().some((item) => {
    if (item.value === value) {
      label = item.label
    }
    return null
  })
  return label || '全国'
}

export function getNewMessageCount (friend) {
  if (!friend) return 0
  let messageCount = 0
  if (friend.type === 'ai') {
    messageCount = isNullOrUndefined(friend.ai_unread_count) ? 0 : friend.ai_unread_count
  } else {
    messageCount = isNullOrUndefined(friend.new_message_count) ? 0 : friend.new_message_count
  }
  return messageCount
}

export function getNewSecretMessageCount (friend) {
  if (!friend) return 0
  let messageCount = 0
  if (!isNullOrUndefined(friend.new_secret_message_count)) {
    messageCount = friend.new_secret_message_count
  }
  return messageCount
}

export function formatPref (value) {
  switch (value) {
    case 'hokkaido': return '北海道'
    case 'fukushima': return '福島'
    case 'miyagi': return '宮城'
    case 'yamagata': return '山形'
    case 'akita': return '秋田'
    case 'iwate': return '岩手'
    case 'aomori': return '青森'
    case 'tokyo': return '東京'
    case 'kanagawa': return '神奈川'
    case 'saitama': return '埼玉'
    case 'chiba': return '千葉'
    case 'ibaraki': return '茨城'
    case 'gunma': return '群馬'
    case 'tochigi': return '栃木'
    case 'fukui': return '福井'
    case 'ishikawa': return '石川'
    case 'toyama': return '富山'
    case 'nigata': return '新潟'
    case 'nagano': return '長野'
    case 'yamanashi': return '山梨'
    case 'shizuoka': return '静岡'
    case 'aichi': return '愛知'
    case 'gifu': return '岐阜'
    case 'mie': return '三重'
    case 'shiga': return '滋賀'
    case 'osaka': return '大阪'
    case 'kyoto': return '京都'
    case 'nara': return '奈良'
    case 'wakayama': return '和歌山'
    case 'hyogo': return '兵庫'
    case 'tottori': return '鳥取'
    case 'shimane': return '島根'
    case 'okayama': return '岡山'
    case 'hiroshima': return '広島'
    case 'yamaguchi': return '山口'
    case 'kagawa': return '香川'
    case 'tokushima': return '徳島'
    case 'kochi': return '高知'
    case 'ehime': return '愛媛'
    case 'fukuoka': return '福岡'
    case 'saga': return '佐賀'
    case 'nagasaki': return '長崎'
    case 'kumamoto': return '熊本'
    case 'oita': return '大分'
    case 'miyazaki': return '宮崎'
    case 'kagoshima': return '鹿児島'
    case 'okinawa': return '沖縄'
    default: return '不明'
  }
}

// カフェテリアポイント利用企業判定
export function canUseCafeteriaPoint (organization) {
  return organization?.benefit === 'cafeteria_point'
}

// リフレッシュ5000利用企業判定
export function canUseRefresh5000 (organization) {
  return organization?.benefit === 'refresh_5000'
}

// iOS/Androidアプリの画面回転関数があれば返す
export function findSetOrientationFunc () {
  // iOS
  if (window.webkit && window.webkit.messageHandlers) {
    return type => window.webkit.messageHandlers.setOrientation?.postMessage(type)
  }
  // Android
  if (window.android) {
    return type => window.android.setOrientation?.(type)
  }
  return null
}

// 特定キャリアメールアドレスの判定
export const isCarrierAddress = addr => {
  const regex = /.+@docomo\./
  return regex.test(addr)
}

export function nationalityIsJapan (value) {
  if (!value || typeof value !== 'string') return false
  return ['日本', 'japan', 'にっぽん', 'にほん', 'ニッポン', 'ニホン'].includes(value.toLowerCase())
}

export async function copyToClipboard (text = '') {
  if (!text) { throw 'コピーに失敗しました' }
  try {
    await navigator.clipboard.writeText(text)
  } catch {
    console.error('navigator.clipboard.writeText() failed.')
    const div = document.createElement('div')
    div.textContent = text
    document.body.appendChild(div)
    document.getSelection().selectAllChildren(div)
    const success = document.execCommand('copy')
    document.body.removeChild(div)
    if (!success) {
      console.error('document.execCommand() failed')
      throw 'コピーに失敗しました'
    }
  }
}

// カタカナ -> ひらがな
function katakanaToHiragana (str) {
  return str.replace(/[\u30a1-\u30f6]/g, function (match) {
    const chr = match.charCodeAt(0) - 0x60
    return String.fromCharCode(chr)
  })
}

// ひらがな -> カタカナ
function hiraganaToKatagana (str) {
  return str.replace(/[\u3041-\u3096]/g, function (match) {
    const chr = match.charCodeAt(0) + 0x60
    return String.fromCharCode(chr)
  })
}

// 正規表現と認識されてしまう文字をエスケープ
function escapeRegExp (str) {
  return str.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&')
}

// カタカナ・ひらがな・大文字・小文字を区別しない正規表現を生成
export function generateFuzzyRegExp (str) {
  str = escapeRegExp(str)
  const chars = str.split('').map(char => {
    const hiragana = katakanaToHiragana(char)
    const katakana = hiraganaToKatagana(char)
    if (hiragana === katakana) return char
    return `(${hiragana}|${katakana})`
  })
  const fuzzyRegExp = new RegExp(`(${chars.join('')})`, 'ig')
  return fuzzyRegExp
}

// 初回トーク時のおすすめ話題アドバイスを取得（elementオブジェクト）
export function getFriendTopicSuggestion () {
  const templateNums = ['02', '03', '05', '18', '22', '28']
  const templateIds = templateNums.map(num => `friend_topic_suggestion-${num}`)
  return templateIds.map(tid => document.getElementById(tid)).filter(Boolean)[0]
}

export function cleanPhoneNumber (phoneNumber) {
  return phoneNumber.replace(/[^\d]/g, '')
}

// 日本の携帯電話番号のバリデーション
export function validatePhoneNumber (phoneNumber) {
  const cleaned = cleanPhoneNumber(phoneNumber)
  return cleaned.match(/^\d{11}$/)
}

export function formatPhoneNumber (number) {
  const cleaned = cleanPhoneNumber(number)

  if (cleaned.length < 4) {
    return cleaned
  } else if (cleaned.length < 8) {
    return `${cleaned.slice(0, 3)}-${cleaned.slice(3)}`
  } else {
    return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 7)}-${cleaned.slice(7, 11)}`
  }
}

export function get2byteLength (str) {
  let count = 0
  for (let i = 0, len = str.length; i < len; i++) {
    let c = str.charCodeAt(i)
    if (!str[i].match(/\r?\n/g)) {
      if (c >= 0x0 && c <= 0x7f) {
        count += 0.5
      } else {
        count += 1
      }
    }
  }
  return count
}
import React, { useState } from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import InputBase from '@material-ui/core/InputBase'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'
import ConfirmDialog from 'components/parts/ConfirmDialog'

const maxDetailLength = 500
const maxOtherLength = 50

const feedbackTypeMap = {
  feedback: 'ご意見・ご要望',
  bug: '不具合',
  inquiry: 'お問い合わせ',
}

const textFieldMap = {
  deviceModel: {
    label: '機種（必須）',
  },
  deviceOS: {
    label: 'OS（任意）',
  },
  problemAt: {
    label: '発生日時（任意）',
  },
  friendInfo: {
    label: '現象のお相手（任意）',
    placeholder: 'ニックネーム等を入力してください。'
  },
  detail: {
    label: '詳細（必須）',
    placeholder: 'ご要望・改善点など何でも自由にご記載ください。',
    multiline: true,
  },
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    minHeight: 800,
    paddingTop: theme.styles.header.height,
    paddingLeft: theme.spacing(3.75),
    paddingRight: theme.spacing(3.75),
    position: 'relative',
  },
  title: {
    fontWeight: 600,
    textAlign: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  selectBox: {
    width: 200,
    fontSize: 16,
    marginBottom: theme.spacing(2),
  },
  label: {
    lineHeight: 1,
    marginBottom: theme.spacing(0.5),
  },
  textfield: {
    width: '100%',
    fontSize: 14,
    border: 'solid 1px #707070',
    borderRadius: 8,
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  buttons: {
    textAlign: 'center',
    position: 'fixed',
    bottom: 12,
    left: '50%',
    transform: 'translate(-50%, -0%)'
  },
  sendButton: {
    width: 230,
    height: 44,
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  cancelButton: {
    fontSize: 12,
    color: theme.palette.secondary.main,
    flexBasis: 'auto',
  },
  error: {
    borderColor: 'red',
  },
  resSubText: {
    fontSize: 12,
  },
}))

export default function Feedback (props) {
  const { ...other } = props
  const classes = useStyles()
  // 種別
  const [feedbackType, setFeedbackType] = useState('')
  // 詳細
  const [detail, setDetail] = useState('')
  // 機種
  const [deviceModel, setDeviceModel] = useState('')
  // OS
  const [deviceOS, setDeviceOS] = useState('')
  // 発生日時
  const [problemAt, setProblemAt] = useState('')
  // 現象のお相手
  const [friendInfo, setFriendInfo] = useState('')
  const [openSentMessage, setOpenSentMessage] = useState(false)
  const [appearSoftKeyboard, setAppearSoftKeyboard] = useState(false)

  const onFocusTextField = () => {
    const innerHeight = window.innerHeight
    const screenHeight = window.screen.height
    setAppearSoftKeyboard(innerHeight !== screenHeight)
  }

  const onBlurTextField = () => {
    window.setTimeout(() => {
      setAppearSoftKeyboard(false)
    }, 100)
  }

  // 詳細バリデーション
  const detailError = () => (detail.length === 0 || detail.length > maxDetailLength)

  // 機種バリデーション
  const modelError = () => (deviceModel.length === 0 || deviceModel.length > maxOtherLength)

  // OS, 発生日時, 現象のお相手バリデーション
  const otherError = () => (
    deviceOS.length > maxOtherLength || problemAt.length > maxOtherLength || friendInfo.length > maxOtherLength
  )

  const sendDisabled = () => (
    feedbackType === 'bug' ? (detailError() || modelError() || otherError()) : detailError()
  )

  const sendFeedback = async () => {
    if (sendDisabled()) { return }
    props.setLoading(true)
    try {
      const param = {
        feedback_type: feedbackType,
        text: detail,
        device_model: deviceModel,
        device_os: deviceOS,
        problem_at: problemAt,
        friend_info: friendInfo,
      }
      await Api.sendFeedback(param)
      setOpenSentMessage(true)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const handleClose = () => {
    BaseComponent.goBack(props)
  }

  const closeSentMessage = () => {
    setOpenSentMessage(false)
    BaseComponent.goBack(props)
  }

  const isNewYearHolidaySeason = () => {
    const mmdd = dateFormat(new Date(), 'mmdd')
    if ('1228' <= mmdd && mmdd <= '1231') { return true }
    if ('0101' <= mmdd && mmdd <= '0105') { return true }
    return false
  }

  const isGoldenWeek = () => {
    const mmdd = dateFormat(new Date(), 'mmdd')
    if ('0427' <= mmdd && mmdd <= '0430') { return true }
    if ('0501' <= mmdd && mmdd <= '0506') { return true }
    return false
  }

  const isObon = () => {
    const mmdd = dateFormat(new Date(), 'mmdd')
    if ('0810' <= mmdd && mmdd <= '0815') { return true }
    return false
  }

  const getThanksMessage = () => {
    if (isNewYearHolidaySeason()) {
      return (
        <div style={{ textAlign: 'left' }}>
          貴重なご意見ありがとうございます。<br />
          12月28日～1月5日は誠に勝手ながら年末年始休業とさせていただいております。<br />
          アプリ不具合以外のお問い合わせにつきましては、1月6日以降に順次返信いたします。<br />
          何卒よろしくお願い申し上げます。
        </div>
      )
    } else if (isGoldenWeek()) {
      return(
        <div style={{ textAlign: 'left' }}>
          貴重なご意見ありがとうございます。<br />
          4月27日～5月6日は誠に勝手ながらGW休業とさせていただいております。<br />
          アプリ不具合以外のお問い合わせにつきましては、5月7日以降に順次返信いたします。<br />
          何卒よろしくお願い申し上げます。
        </div>
      )
    } else if (isObon()) {
      return (
        <div style={{ textAlign: 'left' }}>
          貴重なご意見ありがとうございます。<br />
          8月10日～8月15日は誠に勝手ながらお盆休業とさせていただいております。<br />
          アプリ不具合以外のお問い合わせにつきましては、8月16日以降に順次返信いたします。<br />
          何卒よろしくお願い申し上げます。
        </div>
      )
    } else if (feedbackType === 'feedback') {
      return (
        <div style={{ textAlign: 'left' }}>
          貴重なご意見ありがとうございます。<br />
          サービス向上の参考にさせていただきます。<br />
          引き続きAill goen（エール ゴエン）をよろしくお願いいたします。
        </div>
      )
    } else if (feedbackType === 'bug') {
      return (
        <div style={{ textAlign: 'left' }}>
          ご迷惑をおかけし、申し訳ございませんでした。<br />
          追って、運営よりご登録のメールアドレスへ返信させていただきます。<br />
          どうぞよろしくお願いいたします。
          <div className={classes.resSubText}>
            （1～3営業日以内に返信いたします。なるべく早期の返信ができるよう努めて参ります。）<br />
            <br />
            ※以下のことで解決する場合もございます。<br />
            1）アプリの再起動<br />
            2）通信環境の良い場所でのアプリ利用<br />
            3）通信環境の良い場所で再ログイン
          </div>
        </div>
      )
    } else if (feedbackType === 'inquiry') {
      return (
        <div style={{ textAlign: 'left' }}>
          ご連絡ありがとうございます。<br />
          追って、運営よりご登録のメールアドレスへ返信させていただきます。<br />
          どうぞよろしくお願いいたします。
          <div className={classes.resSubText}>
            （返信は3営業日以内を心がけております。）
          </div>
        </div>
      )
    }
  }

  const createTextField = (id, state, changeStateFunc) => {
    if (id !== 'detail' && feedbackType !== 'bug') { return }
    const label = textFieldMap[id]?.label
    const placeholder = textFieldMap[id]?.placeholder
    const multiline = textFieldMap[id]?.multiline
    const maxLen = multiline ? maxDetailLength : maxOtherLength
    return (
      <>
        <Typography className={classes.label}>{label}</Typography>
        <InputBase
          className={classes.textfield}
          placeholder={placeholder}
          value={state}
          onChange={(e) => changeStateFunc(e.target.value)}
          onFocus={onFocusTextField}
          onBlur={onBlurTextField}
          disabled={!feedbackType}
          error={maxLen < state.length}
          classes={{ error: classes.error }}
          multiline={multiline}
          rows="7"
        />
      </>
    )
  }

  return (
    <div className={classes.root}>
      <AppHeader
        title="お問い合わせ"
        backWhite
        hideHome
        hideBack
        {...other}
      />
      <Typography className={classes.title}>
        Aillでは皆様の屈託のないご意見や<br />
        ご要望を心よりお待ちしています。
      </Typography>
      <Select
        displayEmpty
        value={feedbackType}
        className={classes.selectBox}
        onChange={(e) => setFeedbackType(e.target.value)}
        renderValue={() => (
          <Typography>
            {feedbackType ? feedbackTypeMap[feedbackType] : '種別を選択してください'}
          </Typography>
        )}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          transformOrigin: { vertical: 'top', horizontal: 'center' },
        }}
      >
        {Object.entries(feedbackTypeMap).map(([key, label]) => (
          <MenuItem key={key} value={key}>{label}</MenuItem>
        ))}
      </Select>
      {createTextField('deviceModel', deviceModel, setDeviceModel)}
      {createTextField('deviceOS', deviceOS, setDeviceOS)}
      {createTextField('problemAt', problemAt, setProblemAt)}
      {createTextField('friendInfo', friendInfo, setFriendInfo)}
      {createTextField('detail', detail, setDetail)}
      {!appearSoftKeyboard &&
        <div className={classes.buttons}>
          <Button
            className={classes.sendButton}
            variant="contained"
            color="secondary"
            onClick={sendFeedback}
            disabled={sendDisabled()}
          >
            送信する
          </Button>
          <Button
            className={classes.cancelButton}
            variant="text"
            onClick={handleClose}
          >
            キャンセル
          </Button>
        </div>
      }
      <ConfirmDialog
        open={openSentMessage}
        onClose={closeSentMessage}
        confirm="yes"
        title="お問い合わせ"
        message={getThanksMessage()}
      />
    </div>
  )
}

Feedback.propTypes = {
  setLoading: PropTypes.func,
}

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { calculateHourDiff, calculateMinuteDiff } from 'commons/utility'
import FirstBoostImg from 'images/first_boost.png'

const useStyles = makeStyles(theme => ({
  firstBoostLabel: {
    height: 80,
    fontWeight: 600,
    color: '#ffffff',
    background: '#F55083',
    paddingTop: theme.spacing(2.5),
    position: 'relative',
    boxSizing: 'border-box',
  },
  firstBoostImg: {
    position: 'absolute',
    top: 6,
    left: 50,
  },
  firstBoostEndAt: {
    fontSize: 18,
  },
  firstBoostMessage: {
    fontSize: 12,
  },
}))

export default function FirstBoostBanner (props) {
  const { matchingState } = props
  const classes = useStyles()

  // 初回ブースト残り時間
  const formatFirstBoostRemainingTime = () => {
    const now = new Date()
    const endTime = new Date(matchingState?.first_boost_end_at)
    const hourDiff = calculateHourDiff(now, endTime)
    if (0 < hourDiff) { return `残り${hourDiff}時間！` }

    const minDiff = calculateMinuteDiff(now, endTime)
    return `残り${minDiff}分！`
  }

  return (
    <div className={classes.firstBoostLabel}>
      <img className={classes.firstBoostImg} src={FirstBoostImg} />
      <span className={classes.firstBoostEndAt}>
        {formatFirstBoostRemainingTime()}
      </span>
      <br />
      <span className={classes.firstBoostMessage}>紹介者5人にいいね！で、最大5名追加紹介！</span>
    </div>
  )
}

FirstBoostBanner.propTypes = {
  matchingState: PropTypes.object,
}

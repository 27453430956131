import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import Const from 'commons/constant'
import { entryFooterHight } from 'commons/theme'
import { HEADER_HEIGHT } from 'commons/theme/MembersSiteTheme'
import InvitationCampaignBox from 'components/entry/InvitationCampaignBox'
import InvitationCampaign from 'components/invitation-campaign/InvitationCampaign'
import BenefitGuidance from 'components/members/BenefitGuidance'
import ContentTitle from 'components/members/ContentTitle'
import Coupon from 'components/members/Coupon'
import CurrentPlan from 'components/members/CurrentPlan'
import FreePlanSuggestDialog from 'components/members/FreePlanSuggestDialog'
import MembersCampaignBox from 'components/members/MembersCampaignBox'
import MembersHeader from 'components/members/MembersHeader'
import MembersInfo from 'components/members/MembersInfo'
import PaymentHistory from 'components/members/PaymentHistory'
import ScrollTopButton from 'components/members/ScrollTopButton'
import SelectPlan from 'components/members/SelectPlan'
import WithdrawalDialog from 'components/members/WithdrawalDialog'
import BaseComponent from 'components/parts/BaseComponent'
import Payment from 'components/payment/Payment'
import PaymentInputDialog from 'components/payment/PaymentInputDialog'
import PlanConfirmDialog from 'components/plan/PlanConfirmDialog'
import PurchaseSpecialOffer from 'components/special-offer/PurchaseSpecialOffer'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#FFFFFF',
  },
  body: {
    minHeight: `calc(${window.innerHeight}px - ${entryFooterHight}px)`,
    paddingBottom: theme.spacing(10),
  },
  bodyContainer: {
    width: '100%',
    maxWidth: 1200,
    margin: 'auto',
    paddingRight: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
  },
  infoText: {
    fontSize: 14,
    color: '#1A1667',
    textAlign: 'center',
    marginBottom: theme.spacing(5),
  },
  withdrawalText: {
    color: '#616AFA',
    textDecoration: 'underline',
    marginTop: theme.spacing(10),
  },
}))

export default function MembersPage (props) {
  const {
    plan,
    payment,
    subscription,
    organization,
    billings,
    logout,
    freeTerm,
    campMaster,
    user,
    myPref,
    monthlyFees,
    nextMonthlyFees,
    specialOffers,
    matchingState,
    friends,
    ...others
  } = props
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false)
  const [currentPayment, setCurrentPayment] = useState(null)
  const [openFreePlanSuggestDialog, setOpenFreePlanSuggestDialog] = useState(false)
  const [openWithdrawalDialog, setOpenWithdrawalDialog] = useState(false)
  const [openPlanConfirmDialog, setOpenPlanConfirmDialog] = useState(false)
  const [nextBilling, setNextBilling] = useState(null)
  const [isOpenTopButton, setIsOpenTopButton] = useState(null)
  const classes = useStyles()

  // ページ内スクロール用Ref
  const infoRef = useRef(null)
  const currentPlanRef = useRef(null)
  const campaignRef = useRef(null)
  const selectPlanRef = useRef(null)
  const SpecialOfferRef = useRef(null)
  const cafeteriaRef = useRef(null)
  const paymentRef = useRef(null)
  const paymentHistoryRef = useRef(null)
  const invitationCampaignRef = useRef(null)
  const couponRef = useRef(null)
  const standardPlanRef = useRef(null)
  const fleePlanRef = useRef(null)
  const refs = {
    infoRef, currentPlanRef, campaignRef, selectPlanRef, SpecialOfferRef, cafeteriaRef,
    paymentRef, paymentHistoryRef, invitationCampaignRef, couponRef,
    standardPlanRef, fleePlanRef,
  }

  useEffect(() => {
    const html_el = document.querySelector('html')
    html_el.style.scrollPaddingTop = `${HEADER_HEIGHT + 15}px`
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  const onScroll = () => {
    const position = Math.max(
      window.pageYOffset,
      document.documentElement.scrollTop,
      document.body.scrollTop
    )
    setIsOpenTopButton(position >= 200)
  }

  useEffect(() => {
    if (payment) { setCurrentPayment(payment) }
  }, [payment])

  const handleSentWithdrawalQuestion = () => {
    setOpenWithdrawalDialog(false)
    logout && logout()
  }

  const scroll = target => {
    refs[target].current?.scrollIntoView({ behavior: 'smooth' })
  }

  const onUpdatePayment = newPayment => {
    setOpenPaymentDialog(false)
    setCurrentPayment(newPayment)
    if (shouldOpenPlanConfirmDialog()) {
      setMyNextBilling(subscription.next_plan_id, subscription.payment_months)
      setOpenPlanConfirmDialog(true)
    }
  }

  const setMyNextBilling = async (planId, months) => {
    props.setLoading(true)
    try {
      const nextBilling = await Api.getMyNextBilling(planId, months)
      setNextBilling(nextBilling)
      setOpenPlanConfirmDialog(true)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const onConfirmOK = async (keepFriendUserId) => {
    setOpenPlanConfirmDialog(false)
    props.setLoading(true)
    try {
      const params = {
        content: nextBilling.plan_id,
        payment_months: nextBilling.payment_months,
        keep_friend_user_id: keepFriendUserId,
      }
      await Api.updateMySubscription(params)
      window.location.reload()
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  // 自動更新失敗している状態でお支払い情報更新後のダイアログを出すか判定
  const shouldOpenPlanConfirmDialog = () => {
    if (!subscription.expired_reason) { return false }
    const now = new Date()
    const expTime = new Date(subscription.expired_at)
    return expTime <= now
  }

  const onClickWithdrawalButton = () => {
    if (!subscription) { return }
    if (subscription.next_plan_id === Const.planTypes.STANDARD) {
      setOpenFreePlanSuggestDialog(true)
    } else {
      setOpenWithdrawalDialog(true)
    }
  }

  const checkFreePlan = async () => {
    await setOpenFreePlanSuggestDialog(false)
    scroll('fleePlanRef')
  }

  const onWithdrawal = () => {
    setOpenFreePlanSuggestDialog(false)
    setOpenWithdrawalDialog(true)
  }

  const createInfo = () => {
    const info = [
      (new Date() < new Date('2024-05-07')) && {
        date: '2024.04',
        text: '4月27日~5月6日はGW休業となります。お問合わせ等につきましては5月7日以降に返信させていただきますので、ご了承ください。',
      },
      (new Date() < new Date('2024-08-16')) && {
        date: '2024.08',
        text: '8月10日~8月15日はお盆休業となります。お問合わせ等につきましては8月16日以降に返信させていただきますので、ご了承ください。',
      },
      (new Date() < new Date('2025-01-08')) && {
        date: '2024.12',
        text: '12月28日〜1月5日は年末年始休業となります。お問合わせ等につきましては1月6日以降に順次返信させていただきますので、ご了承ください。',
      },
    ].filter(item => !!item)
    if (info.length === 0) { return null }
    return (
      <Grid container direction="column" alignItems="center" data-testid="info">
        <ContentTitle label="お知らせ" ref={infoRef} />
        <MembersInfo info={info} scroll={scroll} />
      </Grid>
    )
  }

  const createCurrentPlan = () => {
    return (
      <Grid container direction="column" alignItems="center" data-testid="current-plan">
        <ContentTitle label="ご利用中のプラン" ref={currentPlanRef} />
        <CurrentPlan
          plan={plan}
          subscription={subscription}
          organization={organization}
          billings={billings}
          friends={friends}
          monthlyFees={monthlyFees}
          {...others}
        />
      </Grid>
    )
  }

  const createCampaign = () => {
    if (!organization) { return null }
    if (organization.full_paid_enabled) { return null }
    return (
      <Grid container direction="column" alignItems="center" data-testid="campaign">
        <ContentTitle label="キャンペーン" ref={campaignRef} />
        <Typography className={classes.infoText}>現在ご利用いただけるキャンペーンの一覧です。</Typography>
        <MembersCampaignBox
          organization={organization}
          freeTerm={freeTerm}
          campMaster={campMaster}
          subscription={subscription}
          scroll={scroll}
          {...others}
        />
        <InvitationCampaignBox scroll={scroll} />
      </Grid>
    )
  }

  const createSelectPlan = () => {
    return (
      <Grid container direction="column" alignItems="center" data-testid="select-plan">
        <ContentTitle label="プラン変更" ref={selectPlanRef} />
        <SelectPlan
          subscription={subscription}
          organization={organization}
          myPref={myPref}
          monthlyFees={monthlyFees}
          nextMonthlyFees={nextMonthlyFees}
          payment={currentPayment}
          matchingState={matchingState}
          scroll={scroll}
          refs={refs}
          {...others}
        />
      </Grid>
    )
  }

  const createSpecialOffer = () => {
    if (!specialOffers || specialOffers.length === 0) { return null }
    return (
      <Grid container direction="column" alignItems="center" data-testid="select-plan">
        <ContentTitle label="オプション購入" ref={SpecialOfferRef} />
        <PurchaseSpecialOffer {...props} offers={specialOffers} />
      </Grid>
    )
  }

  const createInviation = () => {
    if (!organization) { return null }
    if (organization.full_paid_enabled) { return null }
    return (
      <Grid container direction="column" alignItems="center" data-testid="invitation-campaign">
        <ContentTitle label="お友達紹介" ref={invitationCampaignRef} />
        <Typography className={classes.infoText}>
          お友達にAIll goenを紹介する際に<br />
          お使いいただけるキャンペーンです。
        </Typography>
        <div style={{ background: '#F4F7FE', paddingTop: 32 }}>
          <InvitationCampaign {...others} />
        </div>
      </Grid>
    )
  }

  const createCoupon = () => {
    if (!organization) { return null }
    if (organization.full_paid_enabled) { return null }
    return (
      <Grid container direction="column" alignItems="center" data-testid="coupon">
        <ContentTitle label="クーポンコード" ref={couponRef} />
        <Typography className={classes.infoText}>
          クーポンコードを使って<br />
          お得にAill goenをご利用いただけます！
        </Typography>
        <Coupon subscription={subscription} {...others} />
      </Grid>
    )
  }

  const createMyPayment = () => {
    return (
      <Grid container direction="column" alignItems="center" data-testid="my-payment">
        <ContentTitle label="お支払い方法" ref={paymentRef} />
        <Box width="100%">
          <Payment
            payment={currentPayment}
            onClickUpdate={() => setOpenPaymentDialog(true)}
            {...others}
          />
        </Box>
      </Grid>
    )
  }

  const createPaymentHistory = () => {
    if (!organization) { return null }
    if (!billings) { return null }
    return (
      <Grid container direction="column" alignItems="center" data-testid="payment-history">
        <ContentTitle label="お支払い履歴" ref={paymentHistoryRef} />
        <CurrentPlan
          plan={plan}
          subscription={subscription}
          organization={organization}
          billings={billings}
          next={true}
          monthlyFees={monthlyFees}
          {...others}
        />
        <PaymentHistory billings={billings} organization={organization} {...others} />
      </Grid>
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <MembersHeader
          scroll={scroll}
          logout={logout}
          organization={organization}
          billings={billings}
        />
        <div className={classes.bodyContainer}>
          {createInfo()}
          {createCampaign()}
          {createCurrentPlan()}
          {createSelectPlan()}
          {createSpecialOffer()}
          {createInviation()}
          {createCoupon()}
          {organization?.benefit && <BenefitGuidance organization={organization} scroll={scroll} />}
          {createMyPayment()}
          {createPaymentHistory()}
          <Grid container direction="column" alignItems="center" data-testid="withdrawal">
            <Button
              className={classes.withdrawalText}
              variant="text"
              color="primary"
              onClick={onClickWithdrawalButton}
            >
              退会希望の方はこちら
            </Button>
          </Grid>
        </div>
      </div>
      <FreePlanSuggestDialog
        open={openFreePlanSuggestDialog}
        onClose={() => setOpenFreePlanSuggestDialog(false)}
        checkFreePlan={checkFreePlan}
        onWithdrawal={onWithdrawal}
      />
      <WithdrawalDialog
        {...others}
        open={openWithdrawalDialog}
        onSent={handleSentWithdrawalQuestion}
        onCancel={() => setOpenWithdrawalDialog(false)}
        user={user}
      />
      <PaymentInputDialog
        open={openPaymentDialog}
        onCancel={() => setOpenPaymentDialog(false)}
        onUpdate={onUpdatePayment}
        {...others}
      />
      <PlanConfirmDialog
        open={openPlanConfirmDialog}
        subscription={subscription}
        organization={organization}
        nextBilling={nextBilling}
        onConfirmOK={onConfirmOK}
        onClose={() => setOpenPlanConfirmDialog(false)}
        {...others}
      />
      {isOpenTopButton && <ScrollTopButton />}
    </div>
  )
}

MembersPage.propTypes = {
  plan: PropTypes.object,
  payment: PropTypes.object,
  subscription: PropTypes.object,
  organization: PropTypes.object,
  billings: PropTypes.array,
  logout: PropTypes.func,
  user: PropTypes.object,
  myPref: PropTypes.object,
  monthlyFees: PropTypes.array,
  nextMonthlyFees: PropTypes.array,
  specialOffers: PropTypes.array,
  freeTerm: PropTypes.object,
  campMaster: PropTypes.object,
  matchingState: PropTypes.object,
  setLoading: PropTypes.func,
  friends: PropTypes.array,
}

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import FirstBoostTutorial from 'components/first-navigation/FirstBoostTutorial'
import Tutorial1 from 'components/first-navigation/Tutorial1'
import Tutorial2 from 'components/first-navigation/Tutorial2'
import Tutorial3 from 'components/first-navigation/Tutorial3'

const useStyles = makeStyles(theme => ({
  slide: {
    width: '100%',
    height: window.innerHeight,
    backgroundColor: theme.palette.background.tutorial,
    paddingTop: theme.spacing(4),
  },
  nextButton: {
    fontSize: 16,
    fontWeight: 'bold',
    width: 230,
    height: 44,
    position: 'absolute',
    bottom: theme.spacing(5.5),
  },
}))

export default function Tutorial (props) {
  const { matchingState, onClose } = props
  const classes = useStyles()
  const [slideIndex, setSlideIndex] = useState(0)
  const firstBoostEnabled = Boolean(matchingState?.first_boost_enabled)
  const onChangeSlideIndex = (index, latestIndex, meta) => {
    if (meta.reason === 'swipe') {
      setSlideIndex(index)
    }
  }

  const createTutorialPage = (children) => {
    return (
      <div style={Object.assign({})} className={classes.slide}>
        {children}
      </div>
    )
  }

  const lastPage = () => {
    const pagesLength = firstBoostEnabled ? 3 : 2
    return slideIndex === pagesLength
  }

  return (
    <div>
      {firstBoostEnabled ? (
        <>
          <SwipeableViews index={slideIndex} onChangeIndex={onChangeSlideIndex}>
            {createTutorialPage(<Tutorial1 />)}
            {createTutorialPage(<Tutorial2 />)}
            {createTutorialPage(<FirstBoostTutorial matchingState={matchingState} />)}
            {createTutorialPage(<Tutorial3 {...props} />)}
          </SwipeableViews>

        </>
      ) : (
        <>
          <SwipeableViews index={slideIndex} onChangeIndex={onChangeSlideIndex}>
            {createTutorialPage(<Tutorial1 />)}
            {createTutorialPage(<Tutorial2 />)}
            {createTutorialPage(<Tutorial3 {...props} />)}
          </SwipeableViews>
        </>
      )}
      <Grid container justifyContent="center" alignItems="center">
        <Button
          className={classes.nextButton}
          color="secondary"
          variant="contained"
          disableRipple
          onClick={() => lastPage() ? onClose() : setSlideIndex(slideIndex + 1)}
        >
          {lastPage() ? 'はじめよう！' : '次へ'}
        </Button>
      </Grid>
    </div>
  )
}

Tutorial.propTypes = {
  matchingState: PropTypes.object,
  onClose: PropTypes.func.isRequired,
}
